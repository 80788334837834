type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20240917.1',
	commit: '3df4feb'
};

export { releaseInfo };

